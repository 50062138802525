<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW BANK DEPOSITORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="position==='ADMIN'">
          <v-select
            v-model="type_of_bank"
            class="mx-2"
            dense
            outlined
            label="Purpose"
            :items="type_of_bank_items"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="position==='ADMIN'">
          <v-select
            v-model="bank_details"
            class="mx-2"
            dense
            outlined
            label="Account Type"
            :items="['CHECKING','PAYROLL/SAVINGS','SAVINGS','TIME DEPOSIT','BILLING','REMITTANCE','SECURITIES','INSURANCE','PRECIOUS METAL']"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-form ref="form2" class="multi-col-validation" v-if="position==='ADMIN'">
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="bank_code"
              label="Bank Code"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="bank_name"
              label="Account Name"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="account_no"
              label="Account #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="bank_address"
              label="Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-checkbox label="Is Have CheckBook?" hide-details
                        class="mx-1 mb-4" v-model="is_have_checkbook"></v-checkbox>
          </v-flex>
          <v-flex xs12 md3>
            <v-checkbox label="Is Confidential?" hide-details
                        class="mx-1 mb-4" v-model="is_confidential"></v-checkbox>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Request
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Bank Code
                </th>
                <th class="text-uppercase">
                  Purpose
                </th>
                <th class="text-uppercase">
                  Account Type
                </th>
                <th class="text-uppercase">
                  Bank Name
                </th>
                <th class="text-uppercase">
                  Account #
                </th>
                <th class="text-uppercase">
                  Address
                </th>
                <th class="text-uppercase">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in active_items"
                :key="item.id"
              >
                <td>
                  {{ (active_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.bank_code }}
                </td>
                <td>
                  {{ item.type_of_bank }}
                </td>
                <td>
                  {{ item.bank_details }}
                </td>
                <td>
                  {{ item.bank_name }}
                </td>
                <td>
                  {{ item.account_no }}
                </td>
                <td>
                  {{ item.bank_address }}
                </td>
                <td v-if="item.account_no.length<6">
                  <v-icon class="mr-2" color="info" @click="edit(item)">
                    {{ icons.mdiPencil }}
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Bank Code
                </th>
                <th class="text-uppercase">
                  Purpose
                </th>
                <th class="text-uppercase">
                  Account Type
                </th>
                <th class="text-uppercase">
                  Bank Name
                </th>
                <th class="text-uppercase">
                  Account #
                </th>
                <th class="text-uppercase">
                  Address
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in deactive_items"
                :key="item.id"
              >
                <td>
                  {{ (deactive_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.bank_code }}
                </td>
                <td>
                  {{ item.type_of_bank }}
                </td>
                <td>
                  {{ item.bank_details }}
                </td>
                <td>
                  {{ item.bank_name }}
                </td>
                <td>
                  {{ item.account_no }}
                </td>
                <td>
                  {{ item.bank_address }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="is_edit" persistent max-width="60%">
      <v-card>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mx-2 mt-5">
            <v-layout row wrap class="align-center mx-2 mt-5">
              <v-flex xs12 md3>
                <v-text-field
                  v-model="selected_data.bank_code"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank Code"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="selected_data.bank_name"
                  class="mx-2"
                  dense
                  outlined
                  label="Account Name"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="account_no_new"
                  class="mx-2"
                  dense
                  outlined
                  label="Account #"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="selected_data.bank_address"
                  class="mx-2"
                  dense
                  outlined
                  label="Address"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2)">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="update_bank_account_no"
                  v-if="!saving_data"
                >
                  Update Changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn class="mt-4" color="error" @click="is_edit =false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPencil} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_edit: false,
      saving_data: false,
      alert: false,
      alert_message: '',

      selected_data: {},
      account_no_new: '',
      account_type: '',
      category_id: '',
      category_items: [],
      type_of_bank_items: ['SWEEP FUND', 'EXPENSE', 'RESERVE FUND', 'INVESTMENT', 'REMITTANCE'],
      active_items: [],
      deactive_items: [],
      bank_address: '',
      type_of_bank: '',
      bank_details: '',
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],
      account_no: '',
      bank_code: '',
      bank_name: '',

      is_have_checkbook: false,
      is_confidential: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPencil,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_depository', ['initialize_bank_depository', 'bank_depository_data', 'register_new_bank_depository', 'update_bank_details_account_no']),
      initialize_data() {
        this.initialize_bank_depository()
          .then(response => {
            this.category_items = response.data
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },
      async edit(item) {
        this.selected_data = item
        this.is_edit = true
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        if (this.category_id === 11 || this.category_id === 12) {
          this.type_of_bank_items = ['TRUST FUND', 'SWEEP FUND', 'EXPENSE', 'RESERVE FUND', 'INVESTMENT', 'REMITTANCE']
        } else {
          this.type_of_bank_items = ['SWEEP FUND', 'EXPENSE', 'RESERVE FUND', 'INVESTMENT', 'REMITTANCE']
        }
        const data = new FormData()
        this.type_of_bank = ''
        this.bank_details = ''
        data.append('category_id', this.category_id);
        this.bank_depository_data(data)
          .then(response => {
            this.active_items = response.data[0].active
            this.deactive_items = response.data[0].deactivate
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id);
          data.append('bank_name', this.bank_name.toUpperCase());
          data.append('bank_address', this.bank_address.toUpperCase());
          data.append('bank_code', this.bank_code.toUpperCase());
          data.append('account_no', this.account_no);
          data.append('is_have_checkbook', this.is_have_checkbook);
          data.append('is_confidential', this.is_confidential);
          data.append('type_of_bank', this.type_of_bank);
          data.append('bank_details', this.bank_details);
          this.register_new_bank_depository(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
              this.category_id = cat
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      update_bank_account_no() {
        this.saving_data = true
        this.alert = false
        const data = new FormData()
        data.append('id', this.selected_data.id);
        data.append('account_no', this.account_no_new);
        this.update_bank_details_account_no(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              var color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            this.is_edit = false
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
